@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&family=Rajdhani:wght@300&display=swap');
/*
@font-face {
  font-family: 'Nunito Sans';
  src: url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&display=swap') format("truetype");
}
*/

body {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
}

@media (min-width: 480px) {
  h1 {
    font-size: 1.5rem;
    font-weight: 400;
  }

  h2 {
    font-size: 1.25rem;
    ;
    font-weight: 200;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 1.75rem;
    font-weight: 400;
  }

  h2 {
    font-size: 1.75rem;
    ;
    font-weight: 200;
  }
}