$kendo-color-primary: #458B85;
$kendo-color-secondary: #DC3F3F;
/*
$kendo-color-secondary: #6c757d;
$kendo-color-info: #0dcaf0;
$kendo-color-success: #198754;
$kendo-color-warning: #ffc107;
$kendo-color-error: #dc3545;
$kendo-body-text: #212529;
$kendo-body-bg: #ffffff;
$kendo-subtle-text: #6c757d;
$kendo-disabled-text: #8f8f8f;
$kendo-component-text: #212529;
$kendo-component-bg: #ffffff;
$kendo-base-text: #212529;
$kendo-base-bg: #f8f9fa;
$kendo-hover-text: #16181b;
$kendo-hover-bg: #e9ecef;
$kendo-selected-text: #ffffff;
$kendo-selected-bg: #0d6efd;
$kendo-button-text: #212529;
$kendo-button-bg: #e4e7eb;
$kendo-link-text: #0d6efd;
$kendo-link-hover-text: #0a58ca;
$kendo-series-a: #0d6efd;
$kendo-series-b: #6f42c1;
$kendo-series-c: #20c997;
$kendo-series-d: #198754;
$kendo-series-e: #ffc107;
$kendo-series-f: #dc3545;
*/

$balai-text-color: #1C3835;

@import "~@progress/kendo-theme-bootstrap/dist/all.scss";
@import "@progress/kendo-font-icons/dist/index.css";