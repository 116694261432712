@import "./styles/typography.scss";
@import "./styles/theme.scss";
@import "./styles/plugins.scss";

body {
  color: $balai-text-color;
  overflow-y: hidden;
}

span.k-textbox {
  width: 100%;
}

.logo-wrapper {
  height: get-vw(370px);
  width: get-vw(270px);

}

.logo {
  height: get-vw(186px);
  width: get-vw(270px);
}

.banner {
  height: get-vw(68px);
  color: #2727BE;
  font-weight: 300;
  font-size: get-vw(56px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: get-vw(32px);
}

.account {
  position: relative;
  height: get-vw(20px);
  left: get-vw(31.5px);
  margin: get-vw(32px) 0px get-vw(32px) 0px;
  font-size: get-vw(14px);

}

.account>a {
  margin-left: get-vw(16px);
  color: #0058E9;
}

.continue-with-wrapper {
  display: inline-flex;
  height: get-vw(20px);
  width: get-vw(270px);
  margin-bottom: get-vw(16px);
  margin-top: get-vw(16px);

}

.continue-with-wrapper>span {
  position: static;
  color: #8F8F8F;
  font-size: get-vw(14px);
  line-height: get-vw(20px);

}

.sign-in-page {
  position: relative;
  min-height: 100vh;
  height: 100%;
  display: flex;
  background: #A2C5C2;
}

.sign-in-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  background: #FFFFFF;
  width: get-vw(960px);
  height: get-vh(541px);
  position: relative;


  .sign-in-hello {
    overflow: hidden;

    .si-boba-img {
      height: get-vh(541px);
    }

    /*
    background-size: cover;
    background-repeat: no-repeat;
    width: get-vw(638px);
    height: 100%;
    */
  }

  .inputs-wrapper {
    width: get-vw(480px);
  }
}

.login {
  height: get-vw(202px);
  width: get-vw(270px);
  justify-content: space-between;
  display: flex;
  flex-flow: column;

}

div.k-form-field:nth-child(3) {
  display: flex;
}

.RememberMe-form-label {
  order: 1;
  padding-left: 0.5em;
}

.k-form-field-wrap {
  position: relative;
}


.k-button-text {
  text-decoration: none;
}

.social-wrapper {
  display: flex;
  width: get-vw(194px);
  height: get-vw(30px);
  top: get-vw(755px);
  left: get-vw(183px);

}

.facebook {
  height: get-vw(30px);
  width: get-vw(30px);
  left: get-vw(20px);
  top: get-vw(0px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: get-vw(15px);
  background: rgba(66, 66, 66, 0.04);
  border-radius: get-vw(2px);

}

.twitter {
  height: get-vw(30px);
  width: get-vw(30px);
  left: get-vw(20px);
  top: get-vw(0px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: get-vw(35px);
  background: rgba(66, 66, 66, 0.04);
  border-radius: get-vw(2px);

}

.reddit {
  height: get-vw(30px);
  width: get-vw(30px);
  left: get-vw(20px);
  top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: get-vw(35px);
  background: rgba(66, 66, 66, 0.04);
  border-radius: get-vw(2px);

}

.image-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 61%;
  height: 60%;
  z-index: 9000;
}

.second-image-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 61%;
  height: 60%;
}

.image-wrapper img {
  z-index: 9000;
  margin: auto;
  display: block;
}

.frame-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 61%;
  max-width: get-vw(542px);
  margin: 0 auto;

}

.frame-wrapper h2 {
  font-size: get-vw(48px);
  color: #FFFFFF;
  font-weight: bold;
  text-align: center;
  line-height: 1.4;
}

.frame-wrapper h4 {
  font-size: get-vw(19px);
  text-align: center;
  color: #FFFFFF;
  font-weight: normal;
  line-height: 1.3;
}

.text-wrapper {
  margin-top: 5vh;
}

.dashboard-button {
  text-decoration: none;
}

.overview-button {
  height: get-vw(30px);
  width: get-vw(30px);
}


.drawer {
  border-right-width: get-vw(1px);
  height: 100%;

  &.no-head {
    .k-drawer-content {
      max-height: 100vh;
      height: 100vh;
    }
  }
}

.overview {
  margin-left: get-vw(16px);
  font-style: normal;
  font-weight: 500;
  font-size: get-vw(14px);
}

.right-widget {
  float: right;
  display: flex;
}

.alert-container {
  a {
    color: #424242 !important;
  }
}

.k-avatar {
  height: get-vw(64px);
  width: get-vw(64px);
}

.message-container {
  margin-top: get-vw(16px);
  margin-left: get-vw(8px);

  span {
    width: get-vw(280px);
  }
}

.popup-content {
  margin-top: get-vw(10px);
}

.mark-button {
  height: get-vw(30px);
  width: get-vw(165px);
  float: right;
  border: solid 1px #FF6358;
  color: #FF6358;
  background-color: white;
  right: get-vw(10px);
}

.message-container:nth-child(1) {
  background-color: lightyellow;
}

.k-drawer-content {
  height: 100%;
  width: 100%;
  background-color: #fafafa;
  max-height: calc(100vh - 80px);
}

.brand-container {
  width: 250px;
  max-width: 100%;
  border-width: 0;
  border-right: solid 1px rgba(0, 0, 0, 0.08);
  height: get-vw(80px);

  box-sizing: border-box;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  label {
    font-size: get-vw(20px);
    font-style: normal;
    font-weight: 800;
    margin-bottom: 0;
  }
}

.k-drawer-item {
  align-items: center;

  &.k-selected {
    color: #fff;
  }

  .k-item-text {
    white-space: pre-wrap;
  }
}

a {
  text-decoration: none !important;
}

my-app {
  padding: 0 !important;
}

.k-drawer-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.k-drawer {
  height: 100%;
  max-width: 100%;
  border-width: 0;
  border-style: solid;
  box-sizing: border-box;
  outline: 0;
  font-family: inherit;
  font-size: get-vw(14px);
  line-height: 1.42857143;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  transition: all .3s ease-in-out;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.custom-toolbar {
  padding: get-vw(5px) get-vw(25px);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 300;
  height: get-vw(48px);
  background: #FFFFFF;
  box-shadow: 0px get-vw(3px) get-vw(3px) rgba(0, 0, 0, 0.06);
  transition: width 200ms ease 0s;
  width: calc(100vw - 264px);
  height: get-vw(80px);

  &.fullwidth {
    width: 100vw;
  }

  display:flex;
  align-items: center;
  justify-content: space-between;
}

.k-notification-container {
  .k-widget {
    padding-top: 0px;
  }
}

.k-popup {
  .k-widget {
    padding-top: 0px;
  }
}

.greeting {
  font-weight: 300;
  font-size: get-vw(28px);
  padding: get-vw(24px) 0px get-vw(8px) get-vw(17px);
  background: #fafafa;
}

.dashboard-card-content-number {

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: get-vw(84px);
  line-height: get-vw(80px);
  text-align: center;

}

.dashboard-card-content {
  text-align: center;
}

.green {
  color: #37B400;
}

.red {
  color: #F31700;
}

.orange {
  color: #FFC000;
}

.footer,
.k-card-title {
  color: #666666;
  font-style: normal;
  font-weight: 400;
  font-size: get-vw(20px);
}

.k-card-header {
  border-color: transparent;
}

.gauge-div {
  margin-top: get-vw(-60px);
  text-align: center;
}

.gauge-footer {
  color: #666666;
  font-style: normal;
  font-weight: 400;
  font-size: get-vw(20px);
  line-height: get-vw(42px);
}

.card-buttons {
  display: flex;
  justify-content: space-between;

  margin-top: get-vw(20px);
  margin-right: get-vw(34px);

  Button {
    width: get-vw(106px);
    height: get-vw(30px);
  }

  p {
    margin-left: get-vw(15px);
    font-size: get-vw(20px);
  }

  .k-daterange-picker,
  .k-daterangepicker {
    margin-top: get-vw(-20px);

    label {
      display: none;
    }
  }
}

.chart-container {
  margin-left: get-vw(13px);
  margin-right: get-vw(13px);
}

.k-rating-item.k-selected {
  color: #FFA600;
}

.person-name {
  margin-left: get-vw(10px);
}

.grid-container {
  margin-top: 0px;
  height: get-vw(500px);

  .export-buttons-container {
    margin-left: get-vw(10px);
  }
}

.circular-gauge-container {
  display: flex;
}

.values-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: get-vw(24px);
}

.progress-bar-container {
  display: flex;
  padding-bottom: get-vw(10px);
  color: black;
  font-weight: 700;
  margin-bottom: get-vw(-20px);
  font-size: get-vw(12px);

  span {
    padding: get-vw(5px);
  }
}

.value-span {
  margin-top: get-vw(-7px);
  margin-left: get-vw(21.25px);
}

.country-name-container {
  margin-top: get-vw(-7px);
  margin-right: get-vw(11px);
}

.country-name {
  font-size: get-vw(12px);
  width: get-vw(250px);
}

.box-area {
  margin: get-vw(5px);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.06);
  border-radius: get-vw(3px);
  padding: get-vw(10px);
  width: 99%;
  background-color: #fff;
}

.listbox-card-container {
  margin-top: get-vw(100px);

  .k-listview-content {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .k-icon.k-i-preview {
    z-index: 10;
    bottom: 0px;
    position: inherit;
    right: get-vw(-1px);
    width: get-vw(24px);
    height: get-vw(24px);
    color: gray;
  }

}

.input-container {
  text-align: center;
  margin-top: get-vw(50px);

  input {
    width: get-vw(500px);
  }
}

.footer-buttons-container {
  display: flex;
  flex-direction: column;
  margin-left: get-vw(75px);
  margin-top: get-vw(-7px);
}

.left {
  float: left;
}

.right {
  float: right;
}

.bottom-side {
  margin-top: get-vw(90px);
}

.top-side {
  margin-top: get-vw(820px);
}

.switch-parent,
.f-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: get-vw(70px);
}

.date-container {
  text-align: center;
}

.weather-data {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-bottom: get-vw(1px) solid rgba(0, 0, 0, 0.08);
  width: 100%;
  text-align: center;
}

.weather-data-container {
  margin-top: get-vw(16px);
  padding: get-vw(8px) 0px;
  gap: get-vw(18px);
}

.icon-header-container {
  display: flex;
  justify-content: space-between;

  p {
    color: #666666;
    font-style: normal;
    font-weight: 400;
    font-size: get-vw(20px);
  }

  a {
    font-size: get-vw(20px);
    color: #0058E9 !important;
  }

  .globe-span {
    padding-right: get-vw(15px);
    margin-top: get-vw(-4px);
  }
}

.triple-inputs {
  display: flex;
  justify-content: space-between;
  margin-top: get-vw(30px);
}

.switch-options {
  .switch-parent {
    margin-top: 0px;
    height: get-vw(10px);
  }
}


.card-buttons-parent {
  display: flex;
  justify-content: end;
  margin-top: get-vw(-60px);
  margin-right: get-vw(0px);
}

.gray-text {
  color: #8F8F8F
}

.about {
  margin-left: get-vw(35px);
  margin-top: get-vw(35px);
  margin-bottom: get-vw(35px);
}

.overflow {
  overflow-y: scroll;
}

@media (max-width: 800px) {
  .image-wrapper {
    display: none;
  }
}

.password-icon {
  position: absolute;
  margin-left: 95%;
  margin-top: get-vw(35px);
  color: gray;
}

.switch {
  margin-left: get-vw(20px);
}

.k-dialog-actions {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-block: get-vw(8px);
  padding-inline: get-vw(8px);
  border-width: 1px 0 0;
  border-style: solid;
  border-color: inherit;
  flex: 0 0 auto;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: get-vw(8px);
  overflow: hidden;
  margin: unset;
  margin-inline-end: unset;
  line-height: unset;
  vertical-align: unset;
}

.button-menu-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: get-vw(10px) get-vw(15px);
  border-radius: get-vw(28px);
  background-color: $kendo-color-primary;
  box-shadow: 0px get-vw(3px) get-vw(3px) rgba(0, 0, 0, 0.06);
  cursor: pointer;

  &.active {
    background-color: #DEE9EA;

    .text {
      color: #1C3835;
    }
  }

  .img {
    width: get-vw(28px);
    height: get-vw(28px);
    border-radius: get-vw(20px);
    background-size: cover;
  }

  .text {
    color: #fff;
    font-weight: 600;
    margin-left: get-vw(15px);
    font-size: get-vw(14px);
  }
}

.popup-content {
  .k-listview-content {
    overflow: hidden;
  }

  .menu-profile-item-container {
    width: get-vw(300px);
    margin: get-vw(10px) get-vw(15px);
    padding: get-vw(5px) get-vw(10px);
    border-radius: get-vw(5px);
    font-weight: 500;
    font-size: get-vw(14px);

    &:hover {
      background-color: #DEE9EA;
    }
  }

}

.drawer-top-title {
  height: get-vw(80px);
  box-shadow: 0px get-vw(3px) get-vw(3px) rgba(0, 0, 0, 0.06);
}

.grid-item {
  &.clear-bg {
    background-color: #fff;
  }

  &.topbrr {
    border-top: 1px solid #A2C5C2
  }

  &.botbrr {
    border-bottom: 1px solid #A2C5C2
  }
}

.grid-layout-container {
  max-width: calc(100vw - 260px);
  transition: max-width .3s ease-in-out;

  &.expand {
    max-width: 100vw;
  }
}

.k-drawer-container {
  &.level2 {
    margin-left: 2rem;

    &.hide {
      max-height: 0;
    }

  }
}

.subtitle {
  color: #37504E;
  margin-bottom: get-vw(25px);

  h3 {
    font-weight: get-vw(600px);
    font-size: get-vw(18px);
  }
}


.k-pager-md .k-pager-info {
  display: block;
}

.dialog-soal-detail {
  .k-window-content{
    padding: 0;
  }
}

.k-dialog-titlebar {
  background-color: white;
  height: 2rem;
  border: none;

  .k-button {
    color: black;
  }
}

.action-btn.k-button-md.k-icon-button {
  background-color: $kendo-color-primary;
  color: white;
  padding: get-vw(0.1px);
  height: 1.7rem;
  margin: 0.25rem
}

.k-window-titlebar {
  height: 3rem;

  .k-dialog-titlebar-actions {
    .k-button-md.k-icon-button {
      margin-top: 1vh;
    }
  }
}


.text-error {
  color: $kendo-color-secondary
}

.text-success {
  color: $kendo-color-primary
}

.iframe-view {
  height: 80vh;
  width: 100%;
}

.k-window-content {
  .k-label {
    font-size: 0.9rem;
  }
}


.k-card-body,
.k-taskboard-pane-content {
  .user-list {
    position: relative;
    display: flex;
    align-items: end;
    justify-content: end;
    margin-top: get-vw(10px);

    &.vertical {
      flex-direction: column;
      align-items: flex-start;
      margin-left: get-vw(10px);

      .user-details {
        display: flex;
        align-items: center;
        margin-bottom: get-vw(5px);

        .user-name {
          margin-left: get-vw(4px);
        }

      }
    }

    .user {
      height: get-vw(30px);
      width: get-vw(30px);
      background-size: cover;
      border-radius: get-vw(50px);
      position: relative;
      margin-left: get-vw(-4px);
      border-color: rgba(255, 255, 255, 0.5);
      border-width: get-vw(0.1px);
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.06);

      &.cross {
        border-color: $kendo-color-secondary;
        border-width: get-vw(2px);

        &::after {
          content: " ";
          position: absolute;
          top: get-vw(11px);
          left: get-vw(-2px);
          transform: rotate(45deg);
          background-color: $kendo-color-secondary;
          height: get-vw(3px);
          width: get-vw(30px);
        }
      }

    }
  }
}

.user-item-list {
  display: flex;
  align-items: center;
  justify-content: left;

  .photo {
    height: get-vw(25px);
    width: get-vw(25px);
    border-color: rgba(255, 255, 255, 0.5);
    border-width: get-vw(0.1px);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.06);
    margin: get-vw(1px) get-vw(5px);

    &.user {
      background-size: cover;
      border-radius: get-vw(50px);
    }
  }
}

.progress-style {

  .progress-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    height: get-vw(80px);
    border: 1px solid $kendo-color-primary;
    border-radius: 5px;
    margin: 0 1px;
    width: 100%;

    /*
    &::before {
      content: "";
      width: 0;
      height: 0;
      border-top: 40px solid transparent;
      border-bottom: 40px solid transparent;
      top: 0;
      left: 100%;
      position: absolute;
      border-left: 40px solid $kendo-color-primary;
    }
    */

    .progress-label {
      font-size: 1.1rem;
      font-weight: 900;
      letter-spacing: 0.5px;
    }

    .progress-content {
      margin: 2px 25px;
    }

  }

  &.vertical{
    .progress-item{
      text-align: left;
      border:none;
      justify-content: left;
      height: auto;
      margin-bottom: 7px;
      .progress-label{
        font-size: initial;
        font-weight: normal;
        ::after{
          content: ":";
        }
      }
      .progress-content{
        margin: 0 0 0  5px;
      }

    }
  }
}

.k-widget.k-drawer.k-drawer-start {
  overflow-y: auto;
  max-height: calc(100vh - 100px);
}

.preview-title {
  display: flex;
  align-items: center;

  .view-full {
    margin-left: get-vw(13px);
    padding: get-vw(5px);

    background-color: $kendo-color-primary;
    color: white;
    border-radius: get-vw(5px);
    box-shadow: 0px get-vw(3px) get-vw(3px) rgba(0, 0, 0, 0.06);
  }
}

.stepper {
  width: 62%;
}

.cta-button{
  display: flex;
  justify-content: right;

  .btn-actions{
    display: flex;
    margin-right: 30px;
    .btn-action{
      margin-left: 7px;
    }
  }
}

.setup-paket{
  max-height: 1100px;
  overflow-y: auto;
}