@import '~@syncfusion/ej2-base/styles/material.css';
@import '~@syncfusion/ej2-buttons/styles/material.css';
@import '~@syncfusion/ej2-inputs/styles/material.css';
@import '~@syncfusion/ej2-popups/styles/material.css';
@import '~@syncfusion/ej2-lists/styles/material.css';
@import '~@syncfusion/ej2-navigations/styles/material.css';
@import '~@syncfusion/ej2-splitbuttons/styles/material.css';
@import '~@syncfusion/ej2-dropdowns/styles/material.css';
@import "~@syncfusion/ej2-documenteditor/styles/material.css";


$vw-viewport: 1440;
$vh-viewport: 800;

@function get-vw($target) {
  $vw-context: calc($vw-viewport * 0.01 * 1px);
  @return calc(($target / $vw-context) * 1vw);
}

@function get-vh($target) {
  $vh-context: calc($vh-viewport * 0.01 * 1px);
  @return calc(($target / $vh-context) * 1vh);
}